<template>
  <SiteTemplate>
    <Header :rota="'Meus Pedidos'" />
    <div>
      <Table />
    </div>
  </SiteTemplate>
</template>

<script>
import Table from "@/components/Table.vue";
import Header from '@/components/base/Header.vue'
import SiteTemplate from '@/templates/SiteTemplate.vue'

export default {
  name: "Home",
  components: {
    SiteTemplate,
    Table,
    Header,
  },
  computed: {
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>

</style>

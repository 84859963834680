<template>
  <div>
    <div class="header-table">
      <div :class="{'grid-template-filter':!isMobile}">
        <div class="container-pesquisa">
          <div class="inputSearch">
            <!-- <img src="@/assets/icons/search.svg" class="Pointer search" /> -->
            <input
              type="text"
              placeholder="Pesquisar pedido"
              v-on:input="debounce"
              v-model="search"
              class="input-busca"
            />
          </div>
        </div>

        <b-dropdown
          id="dropdown-status"
          block
          class="status-drop status-drop-filter"
          :text="filterStatusType"
          v-if="!isMobile"
        >
          <b-dropdown-item @click="changeStatusFilter('todo')">
            <div class="d-flex drop-status">
              <div class="ball ball2"></div>
              <span>Todos</span>
            </div>
          </b-dropdown-item>
          <b-dropdown-item @click="changeStatusFilter('send')">
            <div class="d-flex drop-status">
              <div class="ball color1"></div>
              <span>Enviados</span>
            </div>
          </b-dropdown-item>
          <b-dropdown-item @click="changeStatusFilter('delivered')">
            <div class="d-flex drop-status">
              <div class="ball color2"></div>
              <span>Entregues</span>
            </div>
          </b-dropdown-item>
          <b-dropdown-item @click="changeStatusFilter('paid')">
            <div class="d-flex drop-status">
              <div class="ball color3"></div>
              <span>Pedidos pagos</span>
            </div>
          </b-dropdown-item>
          <b-dropdown-item @click="changeStatusFilter('canceled')">
            <div class="d-flex drop-status">
              <div class="ball color4"></div>
              <span>Cancelados</span>
            </div>
          </b-dropdown-item>
          <b-dropdown-item @click="changeStatusFilter('dispatch')">
            <div class="d-flex drop-status">
              <div class="ball color5"></div>
              <span>Em Expedição</span>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div class="flex-sale" v-if="!isMobile">
        <div class="datas">
          <date-range-picker
            ref="picker"
            opens="left"
            :locale-data="localeDate"
            :showWeekNumbers="false"
            :showDropdowns="true"
            :autoApply="false"
            :ranges="dateRanges"
            @update="fetchRequests"
            v-model="dateRange"
          >
            <template
              v-if="dateRange.startDate && dateRange.endDate"
              v-slot:input="picker"
              style="min-width: 350px"
            >
              {{ picker.startDate | date }} - {{ picker.endDate | date }}
            </template>
            <template
              v-else
              v-slot:input
              class="btn-table"
              title="Selecionar datas"
            >
              Selecione as datas
            </template>
          </date-range-picker>
        </div>

        <!-- <b-form-group label-for="type">
          <button class="filtros">
            <img src="@/assets/icons/filtro.svg" />
            Filtros
          </button>
        </b-form-group> -->
      </div>
    </div>

    <b-row class="Table-header mb-1" v-if="!isMobile">
      <b-col cols="1" class="flex-header-icon"> 
        <svg class="right-block" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="10" height="10" rx="2" fill="#E5E5E5"/>
        </svg>
        #Pedido 
      </b-col>
      <b-col cols="2" class="d-none d-md-block"> Status </b-col>
      <b-col cols="2" class="d-none d-md-block"> Data da Compra </b-col>
      <b-col cols="4" md="2" class=""> Cliente </b-col>
      <b-col cols="2" md="2" class=""> Valor </b-col>
      <b-col cols="3" md="2" class=""> Frete </b-col>
      <b-col cols="2" md="1" class=""> Ações </b-col>
    </b-row>

    <b-row class="Table-header mb-1" v-if="isMobile">
      <b-col cols="1" md="1" class=""> #ID </b-col>
      <b-col cols="2" md="2" class=""> Status </b-col>
      <b-col cols="4" md="4" class=""> Cliente </b-col>
      <b-col cols="3" md="3" class=""> Valor </b-col>
      <b-col cols="1" md="1" class=""> Ações </b-col>
    </b-row>

      <template v-if="!loading && sales.length > 0 && isMobile">
      <b-row class="Table-body" v-for="(item, index) in sales" :key="item.id">
        <b-col cols="1" md="1">
          {{ item.id }}
        </b-col>

        <b-col cols="2" md="2" class="d-flex justify-content-center">
          <div class="ball ball2" :class="{
              'color1':item.order_status === 'sent',
              'color2':item.order_status === 'delivered',
              'color3':item.order_status === 'paid',
              'color4':item.order_status === 'canceled',
              'color5':item.order_status === 'dispatch',
            }"></div>
        </b-col>

        <b-col cols="4" md="4" class="mobile-name">
          {{ item.client_name }}
        </b-col>

        <b-col cols="3" md="3" class="">
          <span v-if="item.sale_amount" class="valor-venda">
            R$ {{ formatMoney(+item.sale_amount) }}
          </span>
        </b-col>

        <b-col cols="1" md="1">
          <div class="Table-body-action mr-4" @click="openDetails(item.id)">
            <img src="@/assets/icons/eye.svg" />
          </div>
        </b-col>
      </b-row>
    </template>

    <template v-if="!loading && sales.length > 0 && !isMobile">
      <b-row class="Table-body" v-for="(item, index) in sales" :key="item.id">
        <b-col
          cols="1"
          class="d-none d-md-block"
          id="id_venda"
          v-b-tooltip="{
            title: item.status,
            placement: 'bottom',
          }"
        >
        <svg class="right-block" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="10" height="10" rx="2" fill="#E5E5E5"/>
        </svg>
        # {{ item.id }}
        </b-col>

        <b-col cols="2" class="status-drop">
          <div class="grid-status-table">
            <div class="ball ball2" :class="{
              'color1':item.order_status === 'sent',
              'color2':item.order_status === 'delivered',
              'color3':item.order_status === 'paid',
              'color4':item.order_status === 'canceled',
              'color5':item.order_status === 'dispatch',
            }"></div>
            <b-dropdown
              id="dropdown-status"
              block
              class="status-drop status-drop-table"
              :text="filterStatus(item.order_status)"
            >
              <b-dropdown-item @click="changeStatus('sent', index, item)">
                <div class="d-flex drop-status">
                  <div class="ball color1"></div>
                  <span>Enviado</span>
                </div>
              </b-dropdown-item>
              <b-dropdown-item @click="changeStatus('delivered', index, item)">
                <div class="d-flex drop-status">
                  <div class="ball color2"></div>
                  <span>Entregue</span>
                </div>
              </b-dropdown-item>
              <b-dropdown-item @click="changeStatus('paid', index, item)">
                <div class="d-flex drop-status">
                  <div class="ball color3"></div>
                  <span>Pedido pago</span>
                </div>
              </b-dropdown-item>
              <b-dropdown-item @click="changeStatus('canceled', index, item)">
                <div class="d-flex drop-status">
                  <div class="ball color4"></div>
                  <span>Cancelado</span>
                </div>
              </b-dropdown-item>
              <b-dropdown-item @click="changeStatus('dispatch', index, item)">
                <div class="d-flex drop-status">
                  <div class="ball color5"></div>
                  <span>Expedição</span>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-col>

        <b-col cols="2" class="d-none d-md-block">
          {{ item.sale_created_at | datetime }}
        </b-col>

        <b-col cols="2" class="d-none d-md-block">
          <p>
            {{ item.client_name }}
          </p>
          <p class="phone">
            {{ item.client_cellphone }}
          </p>
        </b-col>

        <b-col cols="4" md="2" class="">
          <span v-if="item.sale_amount" class="valor-venda">
            R$ {{ formatMoney(+item.sale_amount) }}
          </span>
        </b-col>

        <b-col cols="2" class="d-none d-md-block">
          <p v-if="item.service_price">R$ {{ formatMoney(+item.service_price) }}</p>
          <p class="phone" v-if="item.service_price">
            {{ item.service_name }}
          </p>

          <span v-else class="valor-venda">
            Não Calculado
          </span>
        </b-col>

        <b-col cols="3" md="1" class="d-flex">
          <div class="Table-body-action mr-4" @click="openDetails(item.id)">
            <img src="@/assets/icons/eye.svg" />
          </div>
          <div class="Table-body-action" @click="openModalRastremento(item)">
            <img src="@/assets/icons/truck.svg" />
          </div>
        </b-col>
      </b-row>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <b-row>
      <b-col>
        <Paginate
          v-if="sales.length > 0"
          :totalPages="pagination.totalPages"
          :activePage="pagination.currentPage"
          @to-page="toPage"
          @per-page="perPage"
        />
      </b-col>
    </b-row>

    <div
      v-if="loading && !sales.length"
      class="Table-body py-4 d-flex justify-content-center align-items-center"
    >
      <b-spinner variant="dark" label="Spinning"></b-spinner>
    </div>

    <b-row
      v-if="!loading && !sales.length"
      class="Table-body justify-content-center"
    >
      <p class="nao-encontrado">Nenhum pedido foi encontrado</p>
    </b-row>
    <ModalRastreamento :histories="selectedItem.histories" />
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import Paginate from "@/components/base/Paginate";
import ModalRastreamento from "@/components/ModalRastreamento";
import Money from "@/mixins/money";
import moment from "moment-timezone";
import _ from "lodash";

import OrderService from "@/services/resources/OrderService";
const service = OrderService.build();

export default {
  components: {
    DateRangePicker,
    ModalRastreamento,
    Paginate,
  },
  mixins: [Money],
  data() {
    return {
      search: "",
      loading: false,
      sales: [],
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
      localeDate: {
        direction: "ltr",
        format: "mm/dd/yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        firstDay: 0,
      },
      dateRange: {
        startDate: moment().subtract(1, "months"),
        endDate: moment(),
      },
      type_date: "created_at",
      types_list: [
        { value: "created_at", text: "Data de criação" },
        { value: "paid_at", text: "Data de pagamento" },
      ],
      filterStatusType: "Todos",
      filterStatusTypeItem: "todo",
      selectedItem: [],
      client: {
        width: 0,
      },
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      return {
        Hoje: [today, today],
        Ontem: [yesterday, yesterday],
        "Última semana": [lastWeek, today],
        "Este mês": [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), 30),
        ],
        "Este ano": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        "Último mês": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
      };
    },
  },
  mounted() {
    this.fetchRequests();
    console.log(this.$store);
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize() {
      this.client.width = window.innerWidth;
    },
    openDetails(e) {
      this.$router.push({ name: "Pedidos", params: { id: e } });
    },
    openModalRastremento(e) {
      service
        .read(e.id)
        .then((resp) => {
          console.log(resp);
          this.selectedItem = resp;
          this.$root.$emit("bv::show::modal", "modal-rastremento", "#btnShow");
        })
        .catch((err) => {
          console.log(err);
        })
    },
    changeStatusFilter(e) {
      if (e === "paid") {
        this.filterStatusType = "Pago";
        this.filterStatusTypeItem = "paid";
      } else if (e === "canceled") {
        this.filterStatusType = "Cancelado";
        this.filterStatusTypeItem = "canceled";
      } else if (e === "send") {
        this.filterStatusType = "Enviado";
        this.filterStatusTypeItem = "send";
      } else if (e === "delivered") {
        this.filterStatusType = "Entregue";
        this.filterStatusTypeItem = "delivered";
      } else if (e === "dispatch") {
        this.filterStatusType = "Expedição";
        this.filterStatusTypeItem = "dispatch";
      } else if (e === "todo") {
        this.filterStatusType = "Todos";
        this.filterStatusTypeItem = "todo";
      }
      this.fetchRequests();
    },
    filterStatusMobile(e) {
      if (e === "paid") {
        return "Pago";
      } else if (e === "canceled") {
        return "Cancelado";
      } else if (e === "sent") {
        return "Enviado";
      } else if (e === "delivered") {
        return "Entregue";
      } else if (e === "dispatch") {
        return "Expedição";
      }
    },
    filterStatus(e) {
      if (e === "paid") {
        return "Pedido pago";
      } else if (e === "canceled") {
        return "Cancelado";
      } else if (e === "sent") {
        return "Enviado";
      } else if (e === "delivered") {
        return "Entregue";
      } else if (e === "dispatch") {
        return "Expedição";
      }else{
        return "Sem Status";
      }
    },
    changeStatus(e, index, data) {
      // this.loading = true;
      service
        .update({
          id: data.id,
          order_status: e,
        })
        .then((resp) => {
          console.log(resp);
          this.sales[index].order_status = e;
          this.$bvToast.toast("O status foi atualizado com sucesso!", {
            title: "Status!",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          // this.loading = false;
        });
    },
    changedType() {
      this.fetchRequests();
    },
    debounce: _.debounce(function () {
      this.fetchRequests();
    }, 500),
    exportData() {
      this.fetchRequests();
    },
    openFilters() {},
    fetchRequests() {
      this.loading = true;

      var data = {
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
      };
      this.pagination.totalPages = 1;

      if (this.search) {
        data.search_string = this.search;
      }

      if (this.filterStatusTypeItem !== "todo") {
        data.order_status = this.filterStatusTypeItem;
      }

      if (this.dateRange.startDate != null && this.dateRange.endDate != null) {
        data.date_start = moment
          .tz(this.dateRange.startDate, "America/Sao_Paulo")
          .format("YYYY-MM-DDT00:00:00-03:00");
        data.date_end = moment
          .tz(this.dateRange.endDate, "America/Sao_Paulo")
          .format("YYYY-MM-DDT23:59:59-03:00");
      }

      service
        .search(data)
        .then((e) => {
          console.log(e);
          this.sales = e.data;
          this.pagination.totalPages = e.last_page;
          this.pagination.perPage = e.per_page;
          this.pagination.currentPage = e.current_page;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchRequests();
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;
      this.fetchRequests();
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-status-table{
  display: grid;
  grid-template-columns: 1fr 15fr;
  align-items: center;
  .ball2 {
      margin-top: -3px !important;
      margin-right: 0px !important;
  }
}

.grid-template-filter {
  width: 60%;
  display: grid;
  grid-template-columns: 3fr 2fr;
}

.drop-status {
  font-weight: 600;
  font-size: 14px;
  height: 22px;
  color: #000000;
  span {
    margin-top: 4px;
  }
}

.mobile-name{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.flex-header-icon.col-1 {
  white-space: nowrap;
}

.right-block{
  margin-left: -15px;
  margin-right: 5px;
}

.ball {
  width: 10px;
  height: 10px;
  margin-top: 4px;
  margin-right: 10px;
  background: black;
  border-radius: 100%;
}

.color1 {
  background: #2133d2 !important;
}

.color2 {
  background: #00e4a0 !important;
}

.color3 {
  background: #ffcb37 !important;
}

.color4 {
  background: #ff0c37 !important;
}

.color5 {
  background: #37dbff !important;
}
</style>
<template>
  <BaseModal
    name="modal-rastremento"
    size="lg"
    title="Rastreamento do Pedido"
    @hidden="closeModal"
    @shown="openModal"
  >
    <div data-anima="top">
        <Historico :histories="histories" />
    </div>
    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-3" @click="cancel">
        Fechar
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/base/Modal";
import Historico from '@/components/Historico.vue'
// import ConnectionsService from "@/services/resources/ConnectionsService";
// const service = ConnectionsService.build();

export default {
  props:['histories'],
  components: {
    BaseModal,
    Historico
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    openModal() {
      console.log(this.dados);
    },
    closeModal() {
        this.$root.$emit('bv::hide::modal', 'modal-rastremento', '#btnShow');
    },
  },
};
</script>

<style scoped lang="scss">
.size-card-ml {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.custom-width-card {
  width: 154px;
  height: 103px;
}

.title-item {
  color: #2133d2;
}

.text-description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #81858e;
}

.img-title-integration {
  width: 15.43px;
  height: 17.14px;
}

.container-logo {
  display: grid;
  justify-content: center;
  img {
    width: 100%;
    position: relative;
    top: 10px;
  }
}

.card-body {
  cursor: pointer;
}

.title-integration {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #333333;
}
</style>